import { ApolloClient, InMemoryCache } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const wsLink = new GraphQLWsLink(createClient({
    url: 'wss://squid.gdev.coinduf.eu/v1/graphql',
  }));
  
export const squidClient = new ApolloClient({
    link: wsLink,
    cache: new InMemoryCache()
});
